/* Main Container */
.market-container {
    padding: 1rem; /* 16px to rem */
    margin: 0 auto;
    background-color: #f9f9f9;
    text-align: center;
    max-width: 1200px;
}

/* Product Grid */
.market-products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15.625rem, 2fr)); /* 250px to rem */
    gap: 3rem;
    text-align: left;
    margin: 0 auto;
    margin-top: 2rem;
}

/* Product Card */
.market-card {
    background-color: #ffffff;
    border-radius: 0.75rem; /* 12px to rem */
    overflow: hidden;
    box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.1); /* 4px 10px to rem */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.market-card:hover {
    box-shadow: 0 0.375rem 0.9375rem rgba(0, 0, 0, 0.15); /* 6px 15px to rem */
}

/* Product Title */
.market-card-title {
    font-weight: bold;
    margin-bottom: 0.5rem; /* 8px to rem */
    font-size: 1.125rem; /* 18px to rem */
}

/* Product Description */
.market-card-description {
    color: #666;
    margin-bottom: 1rem; /* 16px to rem */
    line-height: 1.5;
    font-size: 1rem; /* 16px to rem */
}

/* Product Price */
.market-card-price {
    font-weight: bold;
    font-size: 1.125rem; /* 18px to rem */
}

/* Responsive Design */
@media (max-width: 48rem) { /* 768px */
    .market-products {
        grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr)); /* 200px to rem */
        padding: 0 5%; /* Adjusted padding for smaller screens */
        margin-top: 3rem;
    }

    .market-card-title {
        font-size: 1rem; /* 16px to rem */
    }

    .market-card-description {
        font-size: 0.875rem; /* 14px to rem */
    }

    .market-card-price {
        font-size: 1rem; /* 16px to rem */
    }
}

@media (max-width: 30rem) { /* 480px */
    .market-products {
        grid-template-columns: 1fr; /* Single column on small screens */
        margin-top: 3rem;
    }

    .market-card {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1); /* Reduced shadow for smaller screens */
    }

    .market-card-title {
        font-size: 0.875rem; /* 14px to rem */
    }

    .market-card-description {
        font-size: 0.75rem; /* 12px to rem */
    }

    .market-card-price {
        font-size: 0.875rem; /* 14px to rem */
    }
}
