.short-news-container {
  width: 700px;
  margin: 0 auto;
  /* border-radius: 10px; */
}

.news-loading, .news-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  color: #fff;
}

/* Featured Article Section */
.short-news-featured {
  position: relative;
  margin-bottom: 40px;
}

.short-news-featured-image {
  width: 100%;
  height: 450px;
  /* Increased for larger screens */
  object-fit: cover;
  /* border-radius: 10px; */
}

/* Overlay for Text */
.short-news-featured-content {
  position: absolute;
  bottom: 0;
  z-index: 2;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  padding: 15px;
  background: rgba(0, 0, 0, 0.6);
  width: auto;
}

/* Category */
.short-news-featured-category {
  writing-mode: vertical-lr;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0px;
  left: -37px;
  letter-spacing: 2px;
  padding: 10px;
  background: #000;
  transform: rotate(180deg);
  cursor: pointer;
}

/* Title */
.short-news-featured-title {
  font-size: 2.5rem;
  /* Scaled for larger screens */
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;
}

/* Description */
.short-news-featured-description {
  font-size: 1.125rem;
  margin-bottom: 15px;
}

/* Author and Meta */
.short-news-featured-author {
  font-size: 0.975rem;
  font-weight: bold;
  color: #00ff00;
  text-transform: uppercase;
  /* Vibrant green */
}

.short-news-featured-meta {
  font-size: 0.675rem;
  color: #aaa;
  margin-left: 10px;
  text-transform: uppercase;
}

/* List of Articles */
.short-news-list {
  display: flex;
  flex-direction: column;
  gap: 22px;
  /* Adjusted spacing between items */
  padding: 25px 0;
  cursor: pointer;
}

.short-news-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  background-color: #101828;
  transition: transform 0.2s ease, background-color 0.2s ease;
  cursor: pointer;
}

.short-news-item:hover {
  transform: translateY(-3px);
}

.short-news-item-content {
  flex: 1;
  padding-right: 15px;
}

/* Vertical Category */
.short-news-item-category {
  writing-mode: vertical-lr;
  text-transform: uppercase;
  font-size: 0.82rem;
  font-weight: bold;
  color: #00d8ff;
  margin-right: 20px;
  border-left: 3px solid #00d8ff;
  padding-left: 5px;
  transform: rotate(180deg);
  text-align: right;
}

/* Title and Meta */
.short-news-item-title {
  font-size: 1.15rem;
  font-weight: bold;
  line-height: 1.4;
  color: #fff;
  margin-bottom: 10px;
}

.short-news-item-meta {
  font-size: 0.975rem;
  color: #aaa;
}

/* Image */
.short-news-item-image {
  flex-shrink: 0;
  width: 170px;
  height: 120px;
  object-fit: cover;
}

/* Modal Styles */
.news-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
  overflow:hidden;
}

.news-modal-content {
  background-color: #111;
  color: #fff;
  padding: 20px;
  /* border-radius: 8px; */
  width: 90%;
  max-width: 800px;
  text-align: left;
  position: relative;
  z-index: 99;
  max-height: 80%;
  /* Prevent overflow on smaller screens */
  overflow-y: auto;
  /* Enable scroll for the content */
  scrollbar-width: thin;
  scrollbar-color: #007aff #111
}

.news-modal-content::-webkit-scrollbar {
  width: 8px;
}

.news-modal-content::-webkit-scrollbar-thumb {
  background-color: #007aff; /* Màu xanh dương */
  border-radius: 4px;
}

.news-modal-content::-webkit-scrollbar-track {
  background-color: #111; /* Màu nền đen */
}

.modal-open {
  overflow: hidden; /* Ngăn cuộn tầng dưới */
}

.news-modal-close {
  position: fixed;
  /* Fixed close button */
  top: 3rem;
  /* 5rem from the top */
  right: 8rem;
  /* Adjust padding from the left */
  background: none;
  border: none;
  color: #fff;
  font-size: 3rem;
  cursor: pointer;
  z-index: 1001;
  /* Ensure it stays on top of the modal content */
}

.news-modal-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.news-modal-category {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #00d8ff;
  margin-bottom: 15px;
  background: #000;
  padding: 5px 10px;
  display: inline-block;
}

.news-modal-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.news-modal-meta {
  font-size: 0.875rem;
  color: #aaa;
  margin-bottom: 20px;
}

.news-modal-description {
  font-size: 1rem;
  line-height: 1.5;
  color: #ccc;
}

/* Media Queries for Responsiveness */
/* Tablets */
@media (max-width: 768px) {
  .short-news-container {
    width: 100%;
    margin: 0 auto;
  }

  .short-news-featured-title {
    font-size: 2rem;
  }

  .short-news-item-title {
    font-size: 1rem;
  }

  .short-news-item-image {
    width: 180px;
    height: 140px;
    object-fit: cover;
  }

  .news-modal-title {
    font-size: 1.5rem;
  }

  .news-modal-content {
    width: 95%;
  }
}

/* Mobile Devices */
@media (max-width: 480px) {
  .short-news-container {
    width: 100%;
    margin: 0 auto;
  }

  .short-news-featured-title {
    font-size: 1.5rem;
    margin: 1.8rem 2.5rem;
  }

  .short-news-featured-description {
    font-size: 1.125rem;
    /* margin: 1rem 2.5rem 2rem; */
  }

  .short-news-featured-category {
    writing-mode: vertical-lr;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    color: #000;
    position: inherit;
    top: 0;
    left: 0;
    letter-spacing: 2px;
    padding: 10px;
    background: #fff;
  }

  .short-news-featured-content {
    position: absolute;
    bottom: -50px;
    z-index: 2;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    padding: 15px;
    background: rgba(0, 0, 0, 0.6);
    width: auto;
  }

  .short-news-item-title {
    font-size: 0.875rem;
  }

  .short-news-item-image {
    width: 120px;
    height: 100px;
    object-fit: cover;
  }

  .short-news-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
    margin-top: 60px !important;
  }

  .news-modal-title {
    font-size: 1.25rem;
  }

  .news-modal-content {
    width: 100%;
    padding: 10px;
  }

  .news-modal-close {
    position: fixed;
    top: 20px;
    background: none;
    border: none;
    color: #fff;
    font-size: 52px;
    cursor: pointer;
    right: 30px;
  }
}
