/* General container for the news detail page */
.short-news-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    color: #fff;
    box-sizing: border-box;
  }
  
  /* Title styling */
  .short-news-container h4 {
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  /* Meta information styling */
  .short-news-container .meta-info {
    font-size: 12px;
    color: gray;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  
  /* Styling for images within the content */
  /* .short-news-container img {
    width: 100%;
    height: auto;
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 40px;
    object-fit: contain;
    display: block;
  } */
  
  /* Styling for markdown description content */
  .content-markdown {
    margin-bottom: 20px;
    color: #e0e0e0;
    font-size: 1rem;
  }
  
  /* Styling for markdown content */
  .markdown {
    line-height: 1.6;
    font-size: 1.1rem;
  }
  
  /* Make sure content inside ReactMarkdown doesn't break layout */
  .short-news-container .content-markdown img {
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 20px; /* Adds space below images */
    margin: 0 auto;
  }
  
  
  /* Responsive breakpoints for smaller screens */
  @media (max-width: 768px) {
    .short-news-container {
      padding: 15px;
    }
  
    .short-news-container h4 {
      font-size: 1.8rem;
    }
  
    .meta-info {
      font-size: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .short-news-container {
      padding: 10px;
    }
  
    .short-news-container h4 {
      font-size: 1.6rem;
    }
  
    .meta-info {
      font-size: 9px;
    }
  
    .markdown {
      font-size: 1rem;
    }
  }
  