.tip-details-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #fff !important; 
  border-radius: 8px;
}

.tip-details-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff !important;
  letter-spacing: 0.3px;
}

.tip-details-meta {
  font-size: 12px;
  color: #a1a1a1; /* Màu xám nhẹ */
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.tip-details-image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  color: #fff !important;
}

.tip-details-content {
  font-size: 16px;
  line-height: 1.6;
  color: #fff !important;
  text-align: left;
}

.tip-details-content ul ol {
  list-style: none !important; /* Loại bỏ dấu chấm đầu dòng */
}

.tip-details-content li {
  margin: 10px auto; /* Thêm khoảng cách giữa các li */
  list-style:circle;
  text-align: left !important;
}

.tip-details-container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.tip-details-loading,
.tip-details-error,
.tip-details-no-data {
  text-align: center;
  font-size: 1.2rem;
  color: #fff !important;
}
