.tips-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.tips-loading,
.tips-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    color: #fff;
}

.tips-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    background: linear-gradient(to right, rgb(81, 255, 188), rgb(4, 19, 112));
    -webkit-background-clip: text; /* Clip nền cho văn bản */
    -webkit-text-fill-color: transparent; /* Làm cho màu văn bản trong suốt */
}

.tips-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 25px;
}

.tip-item {
    background-color: #fff;
    overflow: hidden;
    /* transition: transform 0.3s linear, box-shadow 0.3s ease; */
    /* border-radius: 10px; */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.tip-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.tip-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.tip-content {
    padding: 15px;
}

.tip-link {
    text-decoration: none;
    font-size: 16px !important;
    font-weight: 600;
    color: #333;
}

.tip-link:hover {
    color: #1a73e8;
}

.tip-summary {
    font-size: 14px !important;
    color: #555;
    margin: 20px 0;
}

.tip-meta {
    font-size: 12px;
    color: #888;
}

/* TipsAndTricks.css */

.button-gradient {
    box-shadow: none !important;
    width: 100%;
    /* Đặt chiều rộng đầy đủ */
    padding: 10px;
    /* Thêm khoảng cách */
    border: none;
    /* Bỏ border mặc định */
    background: transparent !important;
    /* Đặt nền trong suốt để chỉ hiển thị border */
    position: relative;
    /* Để sử dụng pseudo-element */
    cursor: pointer;
    /* Thay đổi con trỏ khi hover */
    font-size: 16px;
    /* Kích thước chữ */
    color: #000;
    /* Màu chữ */
    transition: color 0.3s ease;
    /* Hiệu ứng chuyển màu chữ */
}

.button-gradient::after {
    content: '';
    display: block;
    height: 2px;
    /* Chiều cao của border bottom */
    background: linear-gradient(to right, #2937ff, #29f4ff);
    /* Gradient color */
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    /* Đặt nó ở dưới cùng */
    transition: transform 0.6s ease;
    /* Hiệu ứng chuyển động */
    transform: scaleX(0);
    /* Bắt đầu với chiều rộng bằng 0 */
}

.button-gradient:hover::after {
    transform: scaleX(1);
    /* Mở rộng khi hover */
}

.button-gradient:disabled {
    opacity: 0.5;
    /* Giảm độ mờ khi nút bị vô hiệu */
    cursor: not-allowed;
    /* Thay đổi con trỏ khi nút bị vô hiệu */
}

@media (max-width: 768px) {
    .tips-container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 !important;
    }

    .tips-title {
        font-size: 28px;
        font-weight: bold;
        margin-left: 10px;
        margin-bottom: 30px;
    }
}