/* Container chính */
.home-container {
    max-width: 800px;
    /* Chiều rộng tối đa */
    margin: 0 auto;
    /* Căn giữa nội dung */
    color: #000;
    /* border-radius: 12px; */
    background-color: transparent;
    display: flex;
    flex-direction: column;
}

/* Tiêu đề trang */
.home-title {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin-bottom: 6px;
}

/* Danh sách tin tức */
.news-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

/* Mỗi bài viết */
.news-item {
    background-color: #fff;
    color: #000;
    /* border-radius: 12px; */
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(145, 145, 145, 0.1);
}

/* Header */
.news-header {
    display: flex;
    align-items: center;
    padding: 10px;
}

.news-avatar {
    width: 52px !important;
    height: 52px !important;
    border-radius: 50%;
    margin-right: 16px;
    cursor: pointer;
}

.news-author {
    font-size: 16px;
    font-weight: bold !important;
    cursor: pointer;
    text-transform: uppercase;
}

.news-date {
    font-size: 14px;
    color: #b3b3b3;
}

/* Image */
.news-image-container {
    width: 100%;
}

.news-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* Content */
.news-content {
    padding: 16px;
}

.news-title {
    font-size: 22px !important;
    font-weight: bold !important;
    margin-bottom: 15px;
    color: #000;
}

.news-title-link:hover {
    text-decoration: underline;
}

.news-details {
    color: #888;
    font-size: 16px;
    line-height: 1.6;
}

/* Actions */
.news-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    gap: 16px;
    border-top: 1px solid #333;
}

.news-action-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #000;
}

.action-icon {
    color: #1782ff !important;
    transition: color 0.3s ease;
}

.action-icon:hover {
    color: #1e90ff;
}

/* Comments */
.news-comments {
    padding: 16px;
    border-top: 1px solid #333;
}

.comment-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
}

.comment-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
}

.comment-author {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}

.comment-text {
    font-size: 14px;
    color: #fff;
    background: transparent !important;
    padding: 8px 12px;
    /* border-radius: 12px; */
}

/* Nút tham gia thảo luận */
.discussion-button {
    color: #000;
    border-color: #1782ff;
    font-size: 14px;
    padding: 8px 16px;
}

.discussion-button:hover {
    background-color: #1782ff;
    color: #000;
}

.home-news-loading,
.home-news-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    color: #fff;
}

@media (max-width: 768px) {
    .home-container {
        border-radius: 0 !important;
    }

    .news-item {
        border-radius: 0 !important;

    }

    /* Danh sách tin tức */
    .news-list {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }
}