.product-details-container {
    padding: 20px;
    text-align: center;
  }
  
  .product-title {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .product-description {
    color: #666;
    margin-bottom: 20px;
  }
  
  .product-price {
    font-weight: bold;
    color: #3b82f6;
  }