/* Container for the thread details */
.thread-details-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f9fafc;
}

.thread-details-loading, .thread-details-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  color: #fff;
}

/* Section Styling */
.thread-section {
  padding: 0 20px 30px 10px;
  background: #ffffff;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.thread-title {
  font-size: 28px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

.thread-meta {
  font-size: 14px;
  color: #555;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

/* Quy tắc cho phần nội dung của thread */
.thread-content {
  overflow: hidden; /* Giúp ẩn các phần tràn ra ngoài */
  max-width: 100%; /* Đảm bảo chiều rộng không vượt quá 100% */
}

/* Định dạng cho video */
.thread-content video {
  max-width: 100%; /* Video sẽ không vượt quá chiều rộng của container */
  height: auto; /* Giữ tỷ lệ video */
  display: block; /* Đảm bảo video được hiển thị như một khối */
  margin: 0 auto; /* Căn giữa video */
}

/* Định dạng cho các phần tử khác trong thread-content */
.thread-content p {
  margin: 10px 0; /* Thêm khoảng cách giữa các đoạn văn */
}

/* Sử dụng flexbox cho các phần tử con nếu cần */
.thread-content > * {
  margin-bottom: 20px; /* Thêm khoảng cách giữa các phần tử */
}

.thread-content p {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

/* Comments Section */
.comments-section {
  padding: 25px !important;
  background: #ffffff;
  border-top: 1px solid #ddd;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 26px;
}

.comments-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.comment-item {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  border-top: 1px solid #efefef;
  background: #f7f9fb;
  padding: 0 25px;
}

.comment-content {
  flex-grow: 1;
  padding: 10px;
  position: relative;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #555;
}

.comment-author {
  font-weight: bold;
  color: #1f3d65;
  text-transform: uppercase;
}

.comment-date {
  font-size: 10px;
  color: #686894;
  margin-left: 20px;
  margin-top: 5px;
}

.comment-text {
  font-size: 14px;
  color: #333;
  /* margin-top: 15px; */
  /* border: 1px solid #ddd; */
  /* padding: 20px 10px; */
  border-radius: 5px;
}

.comment-content img {
  width: 60%; /* Giới hạn chiều rộng hình ảnh không vượt quá 100% */
  height: auto;    /* Giữ cho tỷ lệ khung hình của hình ảnh */
  display: block;  /* Đảm bảo hình ảnh không bị căn trái hoặc căn phải */
  margin: 0 auto;  /* Canh giữa hình ảnh nếu cần */
}

.comment-replies {
  padding-left: 20px;
  border-left: 1px solid #ddd;
  margin-left: 10px;
  padding-top: 5px;
}

.thread-content img {
  width: 70%; /* Đảm bảo hình ảnh không vượt quá chiều rộng của khối chứa */
  height: auto; /* Giữ tỉ lệ khung hình của hình ảnh */
  display: block; /* Đảm bảo hình ảnh nằm trên một dòng riêng */
  margin: 0 auto; /* Căn giữa hình ảnh */
}

/* Circular Indicator */
.comment-content::before {
  content: "";
  position: absolute;
  left: -8px;
  top: 14px;
  width: 10px !important;
  height: 10px !important;
  /* background-color: #1f3d65; */
  background: linear-gradient(to right, #2937ff, #29f4ff);
}

/* Đặt màu văn bản trong Quill editor là màu đen */
.ql-editor {
  color: black; /* Màu văn bản */
}

/* Đặt màu nền của Quill editor nếu cần */
.ql-container {
  background-color: #ffffff; /* Nền trắng cho editor */
}

/* Đặt màu cho các phần tử khác trong editor nếu cần */
.ql-editor p {
  color: black; /* Màu cho các đoạn văn */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .thread-details-container {
    padding: 15px;
  }

  .thread-section,
  .comments-section {
    padding: 15px;
    margin-top: 30px;
  }

  .comment-item {
    flex-direction: column;
  }

  .comment-content {
    margin-left: 0;
  }

  .comment-replies {
    margin-left: 10px;
  }

  .thread-content img {
    width: 100% !important; /* Đảm bảo hình ảnh không vượt quá chiều rộng của khối chứa */
    height: auto; /* Giữ tỉ lệ khung hình của hình ảnh */
    display: block; /* Đảm bảo hình ảnh nằm trên một dòng riêng */
    margin: 0 auto; /* Căn giữa hình ảnh */
  }
}
