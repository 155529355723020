/* UserWall.css */
.user-wall {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  color: #ffffff;
  background-color: #345169;
  border-radius: 10px;
}

.wall-header {
  text-align: center;
  margin-bottom: 50px;
}

.user-avatar {
  width: 100px !important;
  height: 100px !important;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 50% !important;
  border: 2px solid #ff7a18;
  /* border-image: linear-gradient(45deg, #ff7a18,rgb(215, 239, 107),rgb(9, 142, 53)) 1; */
}

.user-name {
  font-weight: 500 !important;
  text-transform: uppercase;
  margin: 3px auto !important;
}

.details-user-wall-avatar {
  font-weight: 400 !important;
  font-size: 12px !important;
  margin-bottom: 20px !important;
  background: linear-gradient(45deg, #ff7a18,rgb(215, 239, 107),rgb(9, 142, 53)) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.user-info {
  color: #d3d3d3;
  margin-bottom: 20px !important;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.follow-button {
  background-color: #007bff;
  color: #fff !important;
  text-transform: none;
  border-radius: 20px !important;
  font-size: 12px !important;
}

.message-button {
  border-color: #007bff;
  color: #fff !important;
  text-transform: none;
  font-size: 12px !important;
  border-radius: 20px !important;
}

.posts-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.post-card {
  padding: 10px;
  background-color: #2c2c3a;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #dedede
}

.post-avatar {
  width: 52px !important;
  height: 52px !important;
  margin-right: 10px;
}

.post-author {
  font-weight: 600 !important;
  color: #000;
  text-transform: uppercase;
}

.post-date {
  color: #a0a0a0;
  font-size: 12px !important;
}

.post-title {
  font-weight: bold;
  margin: 18px 0 10px 0 !important;
  color: #000;
  font-size: 24px !important;
}

.post-content {
  margin: 28px 0 20px 0 !important;
  color: #494949;
  font-size: 16px !important;
}

.post-image-container {
  text-align: center;
}

.post-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.no-posts {
  text-align: center;
  color: gray;
}

/* Responsive Design */
@media (max-width: 768px) {
  .user-avatar {
    width: 80px;
    height: 80px;
  }

  .post-avatar {
    width: 30px;
    height: 30px;
  }

  .post-title {
    font-size: 1rem;
  }

  .post-content {
    font-size: 0.875rem;
  }
}