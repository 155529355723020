/* src/styles/SendVerificationEmail.css */

.send-verification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    color: #fff;
    padding: 0 20px; /* Thêm padding để tránh cạnh sát màn hình trên mobile */
  }
  
  .send-verification-title {
    font-weight: 600;
    letter-spacing: 1.8px;
    font-size: 28px;
    margin-bottom: 30px !important; /* Tăng khoảng cách dưới tiêu đề */
  }
  
  .send-verification-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto 40px auto !important; /* Thêm margin dưới để tách biệt với các phần tiếp theo */
    border: 1px solid #344054;
    background: #344054;
    border-radius: 5px;
    letter-spacing: 0.8px;
    color: #20c6bf;
    width: 450px;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .send-verification-email {
    font-size: 22px;
    color: #fff;
    font-style: italic;
    word-break: break-all; /* Đảm bảo email không làm bể layout */
    margin-top: 10px; /* Thêm khoảng cách trên email */
  }
  
  .send-verification-error {
    margin: 20px 0 20px 0; /* Tách biệt với các phần tử trên và dưới */
    color: red;
    font-weight: 300;
    font-size: 16px;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .send-verification-success {
    margin: 20px 0 20px 0; /* Tách biệt với các phần tử trên và dưới */
    color: green;
    font-weight: 600;
    font-size: 16px;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .send-verification-button {
    margin-top: 30px; /* Tăng khoảng cách trên nút gửi */
    width: 200px;
    position: relative;
  }
  
  .send-verification-button .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
  
  @media (max-width: 600px) {
    .send-verification-info {
      width: 100%;
      padding: 15px;
      margin: 20px auto 30px auto; /* Điều chỉnh margin trên và dưới cho mobile */
    }
  
    .send-verification-title {
      font-size: 24px;
      margin-bottom: 20px; /* Giảm khoảng cách dưới tiêu đề trên mobile */
    }
  
    .send-verification-email {
      font-size: 18px;
    }
  
    .send-verification-error,
    .send-verification-success {
      font-size: 14px;
      margin: 15px 0; /* Giảm khoảng cách trên và dưới cho mobile */
    }
  
    .send-verification-button {
      width: 100%;
      margin-top: 20px; /* Giảm khoảng cách trên nút gửi trên mobile */
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  