/* Container for the slider */
.image-slider {
  position: relative;
  width: 100%;
  max-width: 50rem; /* 800px to rem */
  margin: 2.5rem auto; /* 40px to rem */
  overflow: hidden;
}

/* Wrapper for the slides */
.image-slider-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 37.5rem; /* 600px to rem */
}

/* Individual slide */
.slider-item {
  min-width: 100%;
  height: 37.5rem; /* 600px to rem */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-item.active {
  opacity: 1;
  position: relative;
  z-index: 2;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Navigation arrows */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  border: none;
  padding: 1rem; /* 16px to rem */
  cursor: pointer;
  /* border-radius: 0.5rem; 8px to rem */
  z-index: 10;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.arrow:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
}

.arrow.left-arrow {
  left: 0.625rem; /* 10px to rem */
}

.arrow.right-arrow {
  right: 0.625rem; /* 10px to rem */
}

/* Arrow inside the button */
.arrow span {
  font-size: 1.5rem; /* 24px to rem */
  font-weight: bold;
  pointer-events: none; /* Ensure only button works */
}

/* Indicator dots */
.slider-indicators {
  position: absolute;
  bottom: 0.9375rem; /* 15px to rem */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.75rem; /* 12px to rem */
  z-index: 10;
}

.indicator {
  /* width: 0.75rem !important; 12px to rem */
  /* height: 0.9375rem !important; 15px to rem */
  background-color: rgba(255, 255, 255, 0.5);
  border: 0.125rem solid rgba(255, 255, 255, 0.5); /* 2px to rem */
  /* border-radius: 50% !important; */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.indicator.active {
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, 1);
}

.indicator:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

/* Ensure arrows and indicators are above the images */
.image-slider {
  position: relative;
}

.slider-image {
  z-index: 1; /* Keep images behind the arrows and indicators */
}

/* Placeholder or Loading animation */
.slider-image {
  background: linear-gradient(90deg, #2c2f36 25%, #3a3f47 50%, #2c2f36 75%);
  background-size: 400% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
      background-position: 0% 0%;
  }
  100% {
      background-position: 100% 0%;
  }
}

/* Responsive adjustments for mobile screens */
@media (max-width: 30rem) { /* 480px to rem */
  .image-slider-wrapper {
      height: 25rem; /* Reduce slider height */
  }

  .slider-item {
      height: 25rem; /* Reduce slide height */
  }

  .arrow {
      padding: 0.75rem; /* Adjust arrow padding */
  }

  .arrow span {
      font-size: 1.25rem; /* Adjust arrow font size */
  }

  .slider-indicators {
      bottom: 0.625rem !important; /* Adjust indicator position */
      gap: 0.5rem !important; /* Reduce gap between dots */
  }
}
