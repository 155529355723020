/* General */
.profile-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #0e1621;
    color: #ffffff;
}

/* Header */
.profile-header {
    text-align: center;
    margin-bottom: 30px;
}

.profile-avatar {
    width: 84px !important;
    height: 84px !important;
    border-radius: 50% !important; /* Giữ avatar và border tròn */
    margin: 1rem auto 2rem auto;
}

.profile-name {
    margin: 1.268rem auto 0.1268rem !important;
    font-size: 1.8rem;
    font-weight: 400;
}

/* KYC Status */
.kyc-status {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    color: #b0b8bf;
}

.kyc-icon {
    font-size: 1.2rem;
    margin-right: 4px;
}

.verified {
    color: #28a745;
    /* Màu xanh lá */
}

.pending {
    color: #ff9800;
    /* Màu cam nhạt */
}

.none {
    color: #e53935;
    /* Màu đỏ nhẹ */
}

.profile-info {
    font-size: 0.8862rem !important;
    color: #b0b8bf;
}

.new-post-btn {
    background-color: #007bff;
    color: #ffffff;
    border-radius: 50px !important;
    padding: 8px 20px !important;
    font-size: 0.9rem !important;
    text-transform: none;
    margin-top: 20px !important;
}

.personal-details-btn {
    background-color: #007bff;
    color: #ffffff;
    padding: 4px 10px !important;
    margin-left: 10px !important;
    font-size: 12px !important;
    text-transform: none;
    margin: 20px auto
}

/* Nút Đăng xuất */
.logout-btn {
    margin-top: 20px !important;
    padding: 4px 10px !important;
    margin-left: 10px !important;
    /* border-color: #3b82f6; */
    /* color: #3b82f6; */
    font-size: 12px !important;
    font-weight: 400;
    text-transform: none;
    transition: all 0.3s ease;
  }
  
  .logout-btn:hover {
    background-color: red;
    color: #ffffff;
  }

.profile-divider {
    margin: 20px 0 !important;
    border-color: rgba(59, 130, 246, 0.3) !important;
}

/* Tabs */
.tabs-wrapper {
    overflow-x: auto;
}

.profile-tabs {
    display: flex;
    white-space: nowrap;
}

.profile-tabs .MuiTab-root {
    text-transform: none;
    font-size: 1rem;
    font-weight: 500;
    min-width: fit-content;
    padding: 0 16px;
    color: #ffffff;
}

.profile-tabs::-webkit-scrollbar {
    display: none;
    /* Ẩn scrollbar trên trình duyệt hỗ trợ */
}

.profile-tabs {
    -ms-overflow-style: none;
    /* Ẩn scrollbar trên IE và Edge */
    scrollbar-width: none;
    /* Ẩn scrollbar trên Firefox */
}

/* Tab Content */
.tab-content {
    margin-top: 20px;
}

.tab-panel {
    background-color: #15293f;
    padding: 20px;
    border-radius: 8px;
    color: #ffffff;
}

/* Responsive */
@media screen and (max-width: 768px) {
    .profile-page {
        padding: 15px;
    }

    .profile-avatar {
        border-radius: 50% !important;
        width: 120px;
        height: 120px;
    }

    .profile-name {
        font-size: 1.5rem;
    }

    .profile-info {
        font-size: 0.9rem;
    }

    .new-post-btn {
        width: 100%;
    }

    .tab-panel {
        padding: 15px;
    }
}
