/* Container styling */
.categories-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 20px;
}

.categories-news-loading, .categories-news-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  color: #fff;
}

/* Title styling */
.categories-title {
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1.6px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.826rem;
  color: #fff;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.category-content {
  font-size: 14px;
  font-style: italic;
  color: #6b6b6b;
}

/* Grid layout for categories */
.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Individual category item */
.category-item {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  text-align: left;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

/* Link styling */
.category-link {
  text-decoration: none;
  color: #0073e6;
  font-weight: 600;
}

.category-link:hover {
  color: #005bb5;
  cursor: pointer;
}

/* Category name */
.category-name {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

/* Additional info */
.category-info {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}

.category-info span {
  font-weight: bold;
  color: #333;
}

/* Hover effect for category items */
.category-item:hover {
  background-color: #eef3f8;
  transform: scale(1.02);
}

/* Fixed title on mobile */
@media (max-width: 768px) {
  .categories-title {
    position: fixed;
    top: -35px;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    color: #333;
    padding: 10px 20px;
    border-bottom: 2px solid #ddd;
  }

  .categories-container {
    max-width: 100% !important;
    padding-top: 60px; /* Add space to avoid overlap with fixed title */
  }

  .categories-grid {
    gap: 15px;
  }
}
