.product-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #ffffff;
  }
  
  .product-list-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .product-card {
    text-align: center;
  }
  
  .product-name {
    font-weight: bold;
  }
  
  .product-price {
    color: #3b82f6;
  }
  
  .product-link {
    text-decoration: none;
    color: #3b82f6;
    font-weight: bold;
  }