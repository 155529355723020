.footer-container {
    background-color: #213243;
    border-top: 2px solid #92abc8;
    padding: 40px 20px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  
  .footer-logo {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
  }
  
  .footer-section {
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .footer-link {
    font-size: 14px;
    color: #fff !important;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #1782ff  !important;
    font-weight: 500;
  }
  
  .footer-bottom {
    border-top: 0.8px solid #92abc8;
    padding-top: 20px;
    margin-top: 20px;
    width: 60%;
  }

  .footer-text-intro {
    font-size: 15px !important;
    color: #cccccc;
    margin: 10px 0;
  }
  
  .footer-text {
    font-size: 8px !important;
    color: #cccccc;
    margin: 10px 0;
  }
  
  .footer-bottom-links {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 0;
  }
  
  .footer-bottom-links .footer-link {
    font-size: 10px;
    color: #cccccc;
  }
  