.details-news-container {
  max-width: 800px;
  margin: 0 auto;
  border-radius: 12px;
  color: #fff;
}

.details-news-title {
  font-size: 1.826rem !important;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: left;
  cursor: pointer;
}

.details-news-header {
  display: flex;
  align-items: center;
  margin: 30px 0 12px 0;
  gap: 8px;
  color: #ffff;
  cursor: pointer;
}

.details-news-avatar {
  width: 52px !important;
  height: 52px !important;
  border-radius: 50% !important;
  color: #ffff;
  /* border: 3px solid; */
  /* border-image: linear-gradient(45deg, #ff7a18, #af002d, #319197) 1; */
}

.details-news-details {
  font-size: 14px;
  line-height: 1.6;
  margin: 3rem auto !important;
  color: #121826 !important;
}

.details-news-content {
  padding: 20px;
}

/* Đặt max-width cho hình ảnh trong Markdown */
.details-news-content img {
  max-width: 100%;
  /* Không vượt quá khung container */
  height: auto;
  /* Tự động điều chỉnh chiều cao */
  margin: 10px 0;
  /* Khoảng cách trên và dưới */
  display: block;
  /* Center align */
  border-radius: 8px;
}

.details-news-paragraph {
  font-size: 16px;
  line-height: 1.6;
  color: #000 !important;
}

.details-news-slider-wrapper {
  margin-bottom: 16px;
}

.details-news-stats {
  padding: 10px 0;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
}

.details-news-stat-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
}

.details-news-stat-item svg {
  color: #F2F4F7;
}


/* Dialog Content */
.dialog-content {
  background-color: #000;
  text-align: center;
  position: relative;
  height: 100%;
  /* Ensure full height for proper centering */
}

/* Fullscreen Image */
.dialog-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #000;
}

/* Close Button */
.dialog-close-button {
  position: fixed !important;
  top: 5rem !important;
  right: 5rem !important;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff !important;
  font-size: 8rem !important;
  z-index: 10;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 99;
}

.dialog-close-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
  /* Darker on hover */
  transform: scale(1.1);
  /* Slight zoom effect */
}

.quill-custom {
  max-width: 800px !important
}

.quill,
.ql-toolbar,
.ql-container {
  color: #fff !important;
}

.details-news-container h1 {
  color: #3b82f6;
  font-size: 25px;
}

.details-news-container a {
  color: #fd971a;
}

.read-aloud-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

/* Nút loa */
.read-aloud-button {
  color: #555;
  transition: color 0.3s, transform 0.3s;
}

.read-aloud-button:hover {
  color: #1976d2;
  /* Màu xanh của MUI */
  transform: scale(1.1);
}

/* Thanh trượt tốc độ đọc */
.speech-rate-slider {
  width: 150px;
  margin-left: 16px;
}

/* Label cho thanh trượt tốc độ */
.speech-rate-label {
  margin-right: 8px;
  font-size: 0.875rem;
  color: #555;
}

/* Dialog Image */
.dialog-image {
  width: 100%;
  height: auto;
}

/* Dialog Close Button */
.dialog-close-button {
  color: #fff;
}

/* Thêm các kiểu dáng khác nếu cần */
.details-news-title {
  margin-top: 16px;
  margin-bottom: 16px;
}

/* Floating Read Aloud Tool */
.floating-read-aloud-tool {
  position: fixed;
  bottom: 8%;
  right: 10%;
  background-color: rgba(255, 255, 255, 0.6);
  /* border-radius: 8px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  padding: 0px 5px;
  display: flex;
  align-items: center;
  z-index: 1000;
}

/* Nút loa trong công cụ popup */
.floating-read-aloud-button {
  color: #555;
  transition: color 0.5s, transform 0.5s;
}

.floating-read-aloud-button:hover {
  color: #0f67ff;
  transform: scale(0.8);
}

/* Thanh trượt tốc độ đọc trong công cụ popup */
.floating-speech-rate-slider {
  width: 200px;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

/* Label cho thanh trượt tốc độ trong công cụ popup */
.floating-speech-rate-label {
  margin-right: 8px;
  font-size: 14px !important;
  color: #555;
  width: 120px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dialog-close-button {
    top: 0.75rem;
    /* Adjust for smaller screens */
    right: 0.75rem;
    font-size: 1.5rem;
    /* Reduce size */
  }

  .details-news-header,
  .details-news-title,
  .details-news-detail,
  .details-news-paragraph {
    padding: 16px !important;
  }

  .details-news-stats {
    display: flex;
    justify-content: space-around;
    font-weight: 300;
    color: #fff;
    cursor: pointer;
  }

  .details-news-content img {
    max-width: 700px;
  }

  .quill-custom {
    width: 100% !important;
  }
}

@media (max-width: 480px) {
  .dialog-close-button {
    top: 0.5rem;
    right: 2rem !important;
    font-size: 2.25rem !important;
    /* Smaller size for mobile */
    position: absolute;
    top: 5rem;
    /* 16px */
    right: 1rem;
    /* 16px */
    z-index: 99 !important;
    color: #fff;
  }

  .details-news-header,
  .details-news-title,
  .details-news-detail,
  .details-news-paragraph {
    padding: 0 25px !important;
  }

  .details-news-content img {
    max-width: 320px;
    margin: 0 auto;
  }

  .quill-custom {
    width: 100% !important;
  }

  .floating-read-aloud-tool {
    position: fixed;
    bottom: 12%;
    right: 5%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
    padding: 0 5px;
    z-index: 1000;
    height: 35px;
  }
}