.service-agreement-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    color: #333;
    font-family: "Arial", sans-serif;
    line-height: 1.6;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  }
  
  .service-agreement-title {
    font-size: 2rem;
    font-weight: bold;
    color: #0d47a1;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .service-agreement-section {
    margin-bottom: 20px;
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1e88e5;
    margin-bottom: 10px;
  }
  
  .service-agreement-paragraph {
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .service-agreement-list {
    list-style: disc;
    margin: 0;
    padding-left: 20px;
  }
  
  .service-agreement-list li {
    margin-bottom: 10px;
  }
  
  .service-agreement-footer {
    margin-top: 20px;
    text-align: center;
    font-size: 0.9rem;
    color: #666;
  }
  
  .email-link {
    color: #1e88e5;
    text-decoration: none;
    font-weight: bold;
  }
  
  .email-link:hover {
    text-decoration: underline;
  }
  