.breadcrumb {
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
  margin: 16px 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap; /* Ngăn không cho phần tử xuống dòng */
  overflow-x: scroll; /* Cho phép cuộn ngang */
  white-space: nowrap; /* Ngăn không cho text tự động xuống dòng */
  /* padding: 0 10px; */
  scrollbar-width: none; /* Ẩn thanh cuộn trên Firefox */
  -ms-overflow-style: none;  /* Ẩn thanh cuộn trên IE và Edge */
}

.breadcrumb::-webkit-scrollbar {
  display: none; /* Ẩn thanh cuộn trên Chrome, Safari, Opera */
}

.breadcrumb-link {
  text-decoration: none;
  color: #0066cc;
  transition: color 0.3s;
  padding: 0 2px;
}

.breadcrumb-link:hover {
  color: #004d99;
}

.breadcrumb-separator {
  color: #999;
  padding: 0 2px;
}

.breadcrumb-thread {
  font-weight: 500;
  color: #333;
  text-transform: uppercase;
  padding: 0 2px;
}

@media (max-width: 600px) {
  .breadcrumb {
    font-size: 12px; /* Giảm kích thước font cho thiết bị nhỏ */
  }
  .breadcrumb-link, .breadcrumb-separator, .breadcrumb-thread {
    padding: 0 2px; /* Giảm padding cho các phần tử */
  }
}
