.personal-details-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #0e1621;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.personal-avatar-section {
  text-align: center;
  margin-bottom: 20px;
}

.personal-avatar {
  width: 100px !important;
  height: 100px !important;
  margin: 0 auto 10px;
  border: 2px solid #3b82f6;
}

.personal-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.personal-email {
  font-size: 1rem;
  color: #a8b3c3;
}

.personal-section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.personal-input {
  margin-bottom: 10px;
  background-color: #1a2431;
  color: #ffffff;
}

.kyc-images img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 5px;
  border-radius: 5px;
  border: 2px solid #3b82f6;
}

.kyc-images {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .personal-details-container {
    margin: 0 auto;
    text-align: center;
  }
}