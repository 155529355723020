.noi-quy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 30px 50px 30px;
    background-color: #fff;
    color: #333;
    font-family: "Arial", sans-serif;
    line-height: 1.6;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  }
  
  .noi-quy-title {
    font-size: 2rem;
    font-weight: bold;
    color: #1e88e5;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .noi-quy-intro {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .noi-quy-section {
    margin-bottom: 20px;
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1565c0;
    margin-bottom: 10px;
  }
  
  .noi-quy-list {
    list-style: disc;
    margin: 0;
    padding-left: 20px;
  }
  
  .noi-quy-list li {
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  .noi-quy-list li:last-child {
    margin-bottom: 0;
  }
  