/* General styles for Login and Register containers */
.auth-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .auth-container h6 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .auth-container .MuiTextField-root {
    margin-bottom: 16px;
  }
  
  .auth-container .MuiButton-root {
    margin-top: 16px;
    font-size: 16px;
    font-weight: bold;
    height: 48px;
    border-radius: 8px;
  }
  
  .auth-container .MuiButton-outlined {
    margin-bottom: 12px;
  }
  
  .auth-divider {
    margin: 54px 0;
    font-size: 14px;
    font-weight: bold;
    color: #555555;
    position: relative;
  }
  
  .auth-divider:before,
  .auth-divider:after {
    content: "";
    height: 1px;
    width: 40%;
    background-color: #dcdcdc;
    position: absolute;
    top: 50%;
  }
  
  .auth-divider:before {
    left: 0;
  }
  
  .auth-divider:after {
    right: 0;
  }
  
  .auth-footer {
    margin-top: 20px;
    font-size: 14px;
  }
  
  .auth-footer a {
    color: #007aff;
    font-weight: bold;
    text-decoration: none;
    margin-left: 5px;
  }
  
  /* Social buttons */
  .auth-social-buttons .MuiButton-root {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    color: #333333;
    border: none;
  }
  
  .auth-social-buttons .MuiButton-root svg {
    margin-right: 10px;
  }
  
  /* Checkbox label */
  .auth-checkbox {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;
    margin-top: 10px;
  }
  
  .auth-checkbox input[type="checkbox"] {
    margin-right: 8px;
  }
  
  /* Recaptcha or other small notices */
  .auth-recaptcha {
    margin: 20px 0;
    font-size: 12px;
    color: #777777;
    text-align: center;
  }
  