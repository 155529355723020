/* Identify Verification Section */
.kyc-section {
    margin-top: 20px;
    color: #fff;
  }
  
  .kyc-select {
    background-color: #fff;
    color: #000;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  
  .kyc-select .MuiSelect-root {
    color: #000;
  }
  
  .kyc-icon-arrow {
    color: #fff !important;
  }
  
  .upload-btn {
    background-color: #4caf50;
    color: #fff;
    margin-top: 20px;
    width: 100%;
  }
  
  .kyc-image-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .kyc-image-upload {
    border: 2px dashed #fff;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    border-radius: 8px;
  }
  
  .kyc-image-upload:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  