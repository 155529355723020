.highlight-container {
  width: 80% !important;
  margin: 0 auto;
}

/* Main Container */
.highlight-header {
  display: flex;
  gap: 2.25rem;
  /* 20px to rem */
  flex-direction: row;
  padding: 2.25rem !important;
  /* 20px to rem */
}

/* Image Section */
.header-image {
  background-size: cover;
  background-position: center;
  width: 40%;
  height: auto;
  aspect-ratio: 4 / 3;
}

/* Content Section */
.header-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

/* Category */
.header-category {
  font-weight: bold !important;
  font-size: 1.628rem !important;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  /* 8px to rem */
  letter-spacing: 3px !important;
  border-bottom: 3px solid chartreuse;
  width: 2.175rem;
  margin-bottom: 0.8rem !important;
}

/* Title */
.header-title {
  font-weight: 700;
  font-size: 2.282rem !important;
  line-height: 1.2;
  margin-bottom: 1rem !important;
  /* 16px to rem */
}

/* Description */
.header-description {
  font-size: 1.225rem !important;
  margin-bottom: 1rem !important;
  /* 16px to rem */
  line-height: 1.6;
  cursor: pointer;
}

/* Author and Date */
.header-author {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  cursor: pointer;
  text-transform: uppercase;
}

/* Footer Icons */
.header-footer-icons {
  display: flex;
  gap: 0.5rem;
  /* 8px to rem */
  align-items: center;
}

.icon {
  font-size: 1rem !important;
  /* 16px to rem */
  margin-top: 0.1125rem !important;
  /* 5px to rem */
  padding: 0.3125rem !important;
  /* 5px to rem */
  text-align: center !important;
  line-height: 1rem !important;
  /* 16px to rem */
  cursor: pointer;
}

.comments {
  font-size: 0.975rem !important;
  font-weight: 500 !important;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  .highlight-container {
    width: 100% !important;
  }

  .highlight-header {
    flex-direction: column;
    gap: 1rem;
    padding: 0 !important;
  }

  .header-content {
    padding: 16px !important;
  }

  .header-image {
    width: auto;
    /* margin: 0.286rem auto; */
    border: 2px solid #e0e0e0;
    border-radius: 0 0 18px 18px;
  }

  .header-title {
    font-size: 1.75rem !important;
    /* 28px to rem */
    font-weight: 500 !important;
  }

  .header-description {
    font-size: 1rem;
    /* 16px to rem */
  }

  .header-author {
    font-size: 0.75rem;
    /* 12px to rem */
  }

  .icon {
    font-size: 0.875rem;
    /* 14px to rem */
  }

  .comments {
    font-size: 0.75rem;
    /* 12px to rem */
  }
}
