.threads-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f9fafc;
}

.thread-list-header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f9fafc;
  font-size: 30px !important;
}

.threads-loading,
.threads-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  color: #fff;
}

.no-threads-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  color: #000;
}

h1 {
  font-size: 18px;
  font-weight: bold;
  color: #1a73e8;
  margin: 35px auto;
}

.thread-item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.thread-item:hover {
  background-color: #f1f1f1;
}

.thread-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  /* Đảm bảo hình ảnh không bị biến dạng */
  display: block;
  /* Loại bỏ khoảng trống bên dưới hình ảnh */

}

.thread-content {
  flex-grow: 1;
}

.thread-content h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
  color: #333;
}

.thread-content h3 a {
  text-decoration: none;
}

.thread-content h3 a:hover {
  color: #1a73e8;
}

.thread-meta {
  font-size: 12px;
  color: #777;
  line-height: 1.4;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Đảm bảo popup nằm trên các phần tử khác */
}

.popup-content {
  background: white;
  padding: 20px;
  width: 800px;
  /* Độ rộng của popup */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  /* Hiệu ứng đổ bóng */
  position: relative;
  /* Để đặt icon X đúng vị trí */
}

.popup-content h6 {
  margin-bottom: 15px;
  /* Khoảng cách giữa tiêu đề và form */
}

.popup-content form {
  display: flex;
  flex-direction: column;
  /* Sắp xếp theo chiều dọc */
}

.popup-content .MuiTextField-root {
  margin-bottom: 15px;
  /* Khoảng cách giữa các input */
}

.popup-content button {
  margin-top: 10px;
  /* Khoảng cách trên cho nút tạo thread */
}

.popup-content .close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  /* Nền trong suốt */
  border: none;
  /* Không có đường viền */
  cursor: pointer;
  /* Con trỏ khi hover */
  color: #888;
  /* Màu sắc cho icon */
  transition: color 0.3s;
  /* Hiệu ứng chuyển màu */
}

.popup-content .close-button:hover {
  color: #f00;
  /* Màu sắc khi hover */
}

.threads-container {
  padding: 20px;
  /* Đệm cho container */
}

.threads-container .MuiButton-root {
  margin: 10px 0;
  /* Khoảng cách trên và dưới cho nút Tạo Thread Mới */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 90%;
  /* Để tránh tràn ra ngoài */
  max-width: 80%;
  /* Để tránh tràn ra ngoài */
  overflow-y: auto;
  /* Thêm cuộn nếu cần */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .threads-container {
    padding: 15px;
    /* margin-top: 20px; */
  }

  .thread-item {
    flex-direction: column;
    gap: 8px;
  }

  .thread-avatar img {
    width: 35px;
    height: 35px;
  }

  .thread-content h3 {
    font-size: 14px;
  }

  .thread-meta {
    font-size: 11px;
  }

  .popup-content {
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-height: 100%;
    /* Để tránh tràn ra ngoài */
    max-width: 100%;
    /* Để tránh tràn ra ngoài */
    overflow-y: auto;
    /* Thêm cuộn nếu cần */
  }
}