/* Tổng thể menu */
.menu-container {
    display: flex;
    align-items: center; /* Căn giữa các phần tử theo chiều dọc */
    justify-content: space-between; /* Đẩy các phần tử ra xa nhau */
    background-color: #fff; /* Màu nền */
    padding: 10px 20px;
    position: sticky; /* Cố định menu trên đầu */
    top: 0;
    z-index: 1000;
    /* border-bottom: 1px solid #15293f; */
  }
  
  /* Logo */
  .menu-logo-container {
    display: flex;
    align-items: center;
  }
  
  .menu-logo-link {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  /* Menu items */
  .menu-items-container {
    margin-left: 2.5rem;
    display: flex;
    gap: 20px; /* Khoảng cách giữa các menu items */
  }
  
  .menu-item {
    text-decoration: none;
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .menu-item:hover {
    color: #000;
  }
  
  .menu-item-active {
    font-weight: 600 !important;
    border-bottom: 2px solid #15293f; /* Nền màu xanh khi hover */
    color: #000;
    font-weight: bold;
  }
  
  /* User Account Button */
  .menu-user {
    background: #15293f !important;
    color: #15293f;
    display: flex;
    align-items: center; /* Đảm bảo icon luôn căn giữa */
  }
  
  .user-icon-button {
    color: #15293f;
    background-color: transparent;
    padding: 8px;
    transition: background-color 0.3s ease;
  }
  
  .user-icon-button:hover {
    background-color: #344054; /* Màu xám đậm khi hover */
  }
  
  .user-icon {
    font-size: 32px;
  }
  
  /* Dropdown menu */
  .dropdown-link {
    text-decoration: none;
    color: #000;
  }
  
  .dropdown-link:hover {
    color: #15293f; /* Màu xanh khi hover */
  }
  