.feature-card-horizontal {
    cursor: pointer;
  }
  
  .feature-card-horizontal:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  .feature-card-content {
    text-align: left;
    color: #ffffff;
  }
  
  .feature-card-image-container img {
    transition: transform 0.3s ease;
  }
  
  .feature-card-image-container:hover img {
    transform: scale(1.1);
  }
  