.header-logo {
    display: flex;
    text-align: center;
    font-size: 22px;
    letter-spacing: 0.228rem;
    text-transform: uppercase;
    font-weight: 200;
}

.header-logo img {
    width: 41px;
    height: 31px;
}

.header-logo span {
    margin-left: 0.8rem;
}
